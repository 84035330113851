import { KeyCode } from "../libs/KeyCode";
import { Overlay } from "../libs/Overlay";
import { Pair } from "../libs/Pair";
import { Time } from "../libs/Time";

export class Globals {
	/** DATA Attributes */
	public static ATTRIBUTE_PREFIX = "data-ez-";

	public static EVENT_SET_ATTRIBUTE = Globals.ATTRIBUTE_PREFIX + "event-set";
	public static MODULE_ID_KEY = Globals.ATTRIBUTE_PREFIX + "id";
	public static MODULE_INITIALIZED = Globals.ATTRIBUTE_PREFIX + "module-initialized";
 
	public static MODULE_ATTRIBUTE_KEY = Globals.ATTRIBUTE_PREFIX + "module-";
	public static PARAMETER_ATTRIBUTE_KEY = Globals.ATTRIBUTE_PREFIX + "param-";
	 
	public static ITEM_ATTRIBUTE_KEY = Globals.ATTRIBUTE_PREFIX + "item";
	public static INPUT_ATTRIBUTE_KEY = Globals.ATTRIBUTE_PREFIX + "input";
	public static OUTPUT_ATTRIBUTE_KEY = Globals.ATTRIBUTE_PREFIX + "output";
 
	public static ELEMENT_ATTRIBUTE = Globals.ATTRIBUTE_PREFIX + "element";
 
	public static DUMMY_ATTRIBUTE_KEY = Globals.ATTRIBUTE_PREFIX + "dummy";
 
	/** HTML IDs*/
	public static ID_PREFIX = "ez-";
 
	/** TEXT*/
	public static MODULE_LOADING_ERROR = "Das Modul konnte nicht gestartet werden, da";

	/** GUI Elements*/
	public static OVERLAY: Overlay = null;

	/** Init*/
	public static init() {
		this.appendElements();
	}

	public static appendElements(): void {
		this.OVERLAY.create( "body" );
	}

	/** Other*/
    public static times:Array<Time> = new Array(
		new Time( "ms", 1 ),
		new Time( "s", 1000 ),
		new Time( "m", 1000 * 60 ),
		new Time( "h", 1000 * 60 * 60 ),
		new Time( "d", 1000 * 60 * 60 * 24 ),
		new Time( "w", 1000 * 60 * 60 * 24 * 7 ),
		new Time( "y", 1000 * 60 * 60 * 24 * 365 )
    );
}

export enum UserAgent {
    DEFAULT,

    CHROME = "chrome",
    SAFARI = "safari",
    FIREFOX = "firefox",
    OPERA = "opera",
    IE = "ie",

    ANDROID = "Android",
    BLACK_BERRY = "BlackBerry",
    IOS = "iOS",
    OPERA_MINI = "pera Mini",
    IE_MOBILE = "IEMobile"
}

export enum OperatingSystem {
    DEFAULT,

    WINDOWS = "windows",
    OSX = "osx",
    LINUX = "linux",
    SEARCH_BOT = "search_bot"
}

export enum ModuleExecutionStatus{
    SUCCESSFULL = "%cStatus [module_name]: %cwurde gestartet.",
    COOKIES = "%cStatus [module_name]: %cwurde nicht gestartet da Cookies vom Benutzer nicht erlaubt wurden.",
    CATCHED_ERROR = "%cStatus [module_name]: %cwurde nicht fehlerfrei gestartet.",
    NOT_CATCHED_ERROR = "%cStatus [module_name]: %ckonnte nicht gestartet werden."
}

/**
 * 
 * //TODO: Set all keycodes defult: OSX with Chrome
 */
 export enum KeyCodes {
    CMD_LEFT = KeyCode.create( 91, [
        new Pair( new Pair( OperatingSystem.DEFAULT, UserAgent.FIREFOX ), 224 ),
        new Pair( new Pair( OperatingSystem.WINDOWS, UserAgent.FIREFOX ), 91 )
    ] ).get(),
    CMD_RIGHT = KeyCode.create( 93, [
        new Pair( new Pair( OperatingSystem.DEFAULT, UserAgent.FIREFOX ), 224 ),
        new Pair( new Pair( OperatingSystem.WINDOWS, UserAgent.FIREFOX ), 91 ),
        new Pair( new Pair( OperatingSystem.WINDOWS, UserAgent.CHROME ), 92 )
    ] ).get(),

    ALT = KeyCode.create( 18 ).get(),
    CTRL = KeyCode.create( 17 ).get(),
    SHIFT = KeyCode.create( 16 ).get(),
    TAB = KeyCode.create( 9 ).get(),
    CAPS_LOCK = KeyCode.create( 20 ).get(),
    
    ENTER = KeyCode.create( 13 ).get(),
    SPACE = KeyCode.create( 32 ).get(),
    ESC = KeyCode.create( 27 ).get(),

    UP = KeyCode.create( 38 ).get(),
    RIGHT = KeyCode.create( 39 ).get(),
    DOWN = KeyCode.create( 40 ).get(),
    LEFT = KeyCode.create( 37 ).get(),

    PAGE_UP = KeyCode.create( 33 ).get(),
    PAGE_DOWN = KeyCode.create( 34 ).get(),
    HOME = KeyCode.create( 36 ).get(),
    END = KeyCode.create( 35 ).get(),
    INSERT = KeyCode.create( 45 ).get(),
    CLEAR = KeyCode.create( 12 ).get(),

    EQUALS = KeyCode.create( 187 ).get(),
    DIVIDE = KeyCode.create( 111 ).get(),
    MULTIPLY = KeyCode.create( 106 ).get(),
    MINUS = KeyCode.create( 109 ).get(),
    PLUS = KeyCode.create( 107 ).get(),

    PLUS_ASTERISK = KeyCode.create( 187, [
        new Pair( new Pair( OperatingSystem.DEFAULT, UserAgent.FIREFOX ), 171 )
    ] ).get(),
    HASH_APOSTROPH = KeyCode.create( 220, [
        new Pair( new Pair( OperatingSystem.WINDOWS, UserAgent.CHROME ), 191 ),
        new Pair( new Pair( OperatingSystem.DEFAULT, UserAgent.FIREFOX ), 163 )
    ] ).get(),
    CIRCUMFLEX = KeyCode.create( 192, [
        new Pair( new Pair( OperatingSystem.DEFAULT, UserAgent.FIREFOX ), 160 ),
        new Pair( new Pair( OperatingSystem.WINDOWS, UserAgent.CHROME ), 220 )
    ] ).get(),
    ACUTE_ACCENT = KeyCode.create( 187, [
        new Pair( new Pair( OperatingSystem.DEFAULT, UserAgent.FIREFOX ), 192 ),
        new Pair( new Pair( OperatingSystem.WINDOWS, UserAgent.CHROME ), 221 )
    ] ).get(),

    BACKSPACE = KeyCode.create( 8 ).get(),
    DELETE = KeyCode.create( 46 ).get(),

    COMMA = KeyCode.create( 188 ).get(),
    DOT = KeyCode.create( 190 ).get(),
    DASH = KeyCode.create( 189, [
        new Pair( new Pair( OperatingSystem.DEFAULT, UserAgent.FIREFOX ), 173 )
    ] ).get(),
    SMALLER_BIGGER_THAN = KeyCode.create( 60, [
        new Pair( new Pair( OperatingSystem.WINDOWS, UserAgent.CHROME ), 226 )
    ] ).get(),

    F1 = KeyCode.create( 112 ).get(),
    F2 = KeyCode.create( 113 ).get(),
    F3 = KeyCode.create( 114 ).get(),
    F4 = KeyCode.create( 115 ).get(),
    F5 = KeyCode.create( 116 ).get(),
    F6 = KeyCode.create( 117 ).get(),
    F7 = KeyCode.create( 118 ).get(),
    F8 = KeyCode.create( 119 ).get(),
    F9 = KeyCode.create( 120 ).get(),
    F10 = KeyCode.create( 121 ).get(),
    F11 = KeyCode.create( 122 ).get(),
    F12 = KeyCode.create( 123 ).get(),

    F13 = KeyCode.create( 124, [
        new Pair( new Pair( OperatingSystem.DEFAULT, UserAgent.FIREFOX ), 44 )
    ] ).get(),
    F14 = KeyCode.create( 125, [
        new Pair( new Pair( OperatingSystem.DEFAULT, UserAgent.FIREFOX ), 145 )
    ] ).get(),
    F15 = KeyCode.create( 126, [ 
        new Pair( new Pair( OperatingSystem.DEFAULT, UserAgent.FIREFOX ), 19 )
    ] ).get(),

    F16 = KeyCode.create( 127 ).get(),
    F17 = KeyCode.create( 128 ).get(),
    F18 = KeyCode.create( 129 ).get(),
    F19 = KeyCode.create( 130 ).get(),

    A = KeyCode.create( 65 ).get(),
    B = KeyCode.create( 66 ).get(),
    C = KeyCode.create( 67 ).get(),
    D = KeyCode.create( 68 ).get(),
    E = KeyCode.create( 69 ).get(),
    F = KeyCode.create( 70 ).get(),
    G = KeyCode.create( 71 ).get(),
    H = KeyCode.create( 72 ).get(),
    I = KeyCode.create( 73 ).get(),
    J = KeyCode.create( 74 ).get(),
    K = KeyCode.create( 75 ).get(),
    L = KeyCode.create( 76 ).get(),
    M = KeyCode.create( 77 ).get(),
    N = KeyCode.create( 78 ).get(),
    O = KeyCode.create( 79 ).get(),
    P = KeyCode.create( 80 ).get(),
    Q = KeyCode.create( 81 ).get(),
    R = KeyCode.create( 82 ).get(),
    S = KeyCode.create( 83 ).get(),
    T = KeyCode.create( 84 ).get(),
    U = KeyCode.create( 85 ).get(),
    V = KeyCode.create( 86 ).get(),
    W = KeyCode.create( 87 ).get(),
    X = KeyCode.create( 88 ).get(),
    Y = KeyCode.create( 89 ).get(),
    Z = KeyCode.create( 90 ).get(),

    _0 = KeyCode.create( 48 ).get(),
    _1 = KeyCode.create( 49 ).get(),
    _2 = KeyCode.create( 50 ).get(),
    _3 = KeyCode.create( 51 ).get(),
    _4 = KeyCode.create( 52 ).get(),
    _5 = KeyCode.create( 53 ).get(),
    _6 = KeyCode.create( 54 ).get(),
    _7 = KeyCode.create( 55 ).get(),
    _8 = KeyCode.create( 56 ).get(),
    _9 = KeyCode.create( 57 ).get()
}

Globals.OVERLAY = new Overlay();