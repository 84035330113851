import { Helpers } from "./classes/handlebars/Helpers";
import { Logging } from "./classes/logging/Logging";
import { AjaxRequest } from "./libs/v2/AjaxRequest";
import { Cookies } from "./libs/Cookies";
import { Elements } from "./libs/Elements";
import { Globals, KeyCodes, ModuleExecutionStatus } from "./classes/Globals";
import { Json } from "./libs/Json";
import { KeyEvents } from "./libs/KeyEvents";
import { Pair } from "./libs/Pair";
import { Tools } from "./libs/Tools";
import { Windows } from "./libs/Windows";
import { Module } from "./classes/mvc/Module";
import { Model } from "./classes/mvc/Model";
import { Times } from "./libs/Times";
import { Interfaces } from "./libs/Interfaces";
import { IOverlay } from "./classes/IOverlay";
import { Collapse } from "./classes/Collapse";
import { Strings } from "./libs/Strings";
import { ArticleText } from "../src/modules/Article/ArticleText";
import { EZModule } from "./classes/v2/EZModule";
import { inflate } from 'pako';
import { load } from 'js-yaml';

require("./resources/styles/all.scss"); // Needed for Webpack CSS generation
var modulesSetup = require("./modules-setup.json");// lader der module-setup.json

declare let ezentrum_variables: any; // Global Var (All ezentrum-shop-vars)
declare let conf_file_path: any; // Global Var (All ezentrum-shop-vars)
declare var window: any; // Global Var (window -> General dom window)
declare let compressedData:Uint16Array;
declare let ezentrum_art_attributes:any; // Available in ezentrum article detail page


export class Modules {
    // Public Static Variables
    public static VERSION = modulesSetup.version; // TODO: Check modul-setup --> It will be never used. Check to eleminate it.
    public static modulesVariables: any; // Globale eZentrum Variablen
    public static isInShopView: boolean; // Status: True => im Shop, Status: False => CMS
    public static isNewSessionStucture: boolean; // Status: True => neuer Aufbau, Status: False => Altes Modulsystem bis 05.07.2021
    // Public Variables
    public modulesConfiguration: Object;   // Inkludiert die Konfiguration für alle Module - conf.json / conf.yaml -> modules
    public modules: Array<Module<Model>>; // Global Array of all Modules
    // Privat Variables
    private globalConfigurations: Object;   // Inkludiert die Konfiguration für alle Module - conf.json / conf.yaml -> global
    private template: string; // Inkludiert die Konfiguration für alle Module - conf.json / conf.yaml -> z.B.: foundation
    private language: Pair<number, string>; // stellt die Sprache ein per ID
    private startTime: number; // Startzeit, wird beim Logging verwendet
    private endTime: number;    // Endzeit, wird beim Logging verwendet
    private flagAllowCookies: boolean;  // Zeigt an, ob Cookies erlaubt sind oder nicht
    private sKontaktID: string; // Session Kontakt ID
    private sKontaktKEY: string;    // Session Kontakt Key
    private sTICKCOUNT: string; // Session Tick Count
    private configuration: any;

    // @Chris
    private v2Modals:any[] = [];


    // Constructor
    public constructor() {
    }

    // method: checkRequirements
    // returns false, if requirements are not ok.
    public async checkRequirements(): Promise<boolean> {
        var confFilePath: string = ""; // Pfad zu der Konfigurationsdatei
        if ( typeof conf_file_path !== 'undefined' )  // Exists global this.configuration var in html site?
            confFilePath = conf_file_path;
        else // Try to load the default yaml location
            confFilePath =  modulesSetup.defaultConfPath + ".yaml";
        // this.configuration = await AjaxRequest.getYaml(confFilePath);
        if ( typeof compressedData != 'undefined') {
            this.configuration = inflate(compressedData, { to: 'string'});
            this.configuration = load(this.configuration);
        }
        else {
            this.configuration =  await AjaxRequest.getYaml(confFilePath);
        }

        if ( this.configuration.global.use_bundle_jquery ) // Use global JQuery in ezentrum modul system?
        {
            window.jQuery = jQuery;
            window.$ = jQuery;
        }
        let language = document.documentElement.lang; // Get the html document language
        if ( typeof ezentrum_variables !== 'undefined' )  // Is global var ezentrum_variables set in the html? 
        {
            Modules.isInShopView = true;
            this.sKontaktID = ezentrum_variables.sKontaktID;
            this.sKontaktKEY = ezentrum_variables.sKontaktKEY;
            this.sTICKCOUNT = ezentrum_variables.sTICKCOUNT;
        } else
        {
            // Block: Scope is outside the shop system --> Load the session vars from the shopping system.
            Modules.isInShopView = false;
            let url: string;
            if ( this.configuration.global.sessionVars || this.configuration.global.sessionVars !== '' ) // Shop with one language? global.sessionVars="url_to_sync" is set.
                url = this.configuration.global.sessionVars;
            else // Shop with multi-language: global.sessionVars_lang definition is set.
            {
                let sessionvars_url = this.configuration.global.sessionVars_lang.find((obj:any) => obj.html_lang === language);
                url = sessionvars_url.url;
            }
            Modules.modulesVariables = await AjaxRequest.getPlainText(url); // Receive the shopping session vars from the above idendified setup in the yaml-file
            if (Modules.modulesVariables.customer_sync) // Sync elements for external sites (Customer, Basket, New Products, Session-Id, Session-Key, Tickcount)? --> If customer_sync, process all elements.
            {
                // Block: Account Sync
                let customerHTMLElement:HTMLElement=document.getElementById("ezentrum-header-customer") as HTMLElement; // Customer sync
                if (customerHTMLElement) {
                    customerHTMLElement.innerHTML=Modules.modulesVariables.customer_sync // set customer values 
                    if ( this.configuration.global.wpModalFix ) {
                        const modal = document.getElementById('loRegModal');
                        if ( !modal ) console.log('loRegModel not found!');
                        else {
                            // Add event listener for modal show event
                            modal.addEventListener('show.bs.modal', onModalShow);

                            // Add event listener for modal hide event
                            modal.addEventListener('hide.bs.modal', onModalHide);

                            function onModalShow() {
                                const wrapperEle = document.getElementById('ez_wrapper');
                                const headerEle = document.getElementById('ez_header_fullwidth');
                                if ( wrapperEle && headerEle ) {
                                    wrapperEle.style.zIndex = '1051';
                                    wrapperEle.style.position = 'relative';
                                    headerEle.style.zIndex = 'inherit';
                                    modal.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
                                }
                            }
                            // Function to be executed when the modal is hidden
                            function onModalHide() {
                                const wrapperEle = document.getElementById('ez_wrapper');
                                const headerEle = document.getElementById('ez_header_fullwidth');
                                if ( wrapperEle && headerEle ) {
                                    wrapperEle.style.zIndex = '';
                                    wrapperEle.style.position = '';
                                    headerEle.style.zIndex = '';
                                }
                            }
                        }
                    }
                }

                let customerMobileHTMLElement:HTMLElement=document.getElementById("ezentrum-header-mobile-customer") as HTMLElement; // Customer mobile sync
                if (customerMobileHTMLElement)
                    customerMobileHTMLElement.innerHTML=Modules.modulesVariables.customer_sync_mobile // set customer values 
                // Block: Basket Sync
                let basketMobileHTMLElement:HTMLElement=document.getElementById("ezentrum-header-mobile-basket") as HTMLElement; // Basket mobile sync
                if (basketMobileHTMLElement)
                    basketMobileHTMLElement.innerHTML=Modules.modulesVariables.basket_sync_mobile // set customer mobile basket values 
                let basketHTMLElement:HTMLElement=document.getElementById("ezentrum-header-basket") as HTMLElement; // Shopping Basket sync
                if (basketHTMLElement)
                    basketHTMLElement.innerHTML=Modules.modulesVariables.basket_sync // set basket value
                // Block: Offers Sync
                let newProductsHTMLElement: HTMLElement=document.getElementById("ez_newproducts") as HTMLElement; // New Products sync
                if (newProductsHTMLElement)
                    newProductsHTMLElement.innerHTML=Modules.modulesVariables.ads_new; // set offers
                // Block: Footer Sync
                let footerHTMLElement: HTMLElement=document.getElementById("ez_footer_fullwidth") as HTMLElement; // New Products sync
                if (footerHTMLElement)
                    footerHTMLElement.innerHTML=Modules.modulesVariables.footer_sync; // set offers


                let collection:HTMLCollectionOf<HTMLInputElement> = document.getElementsByClassName("ezentrum-session-key") as HTMLCollectionOf<HTMLInputElement> ; // Set all session-keys
                for (let i = 0; i < collection.length; i++) // Replace all session-keys for the identifed html-elements in the document.
                {
                    collection[i].value = Modules.modulesVariables.required.contact_id;
                }
                collection = document.getElementsByClassName("ezentrum-session-id") as HTMLCollectionOf<HTMLInputElement> ; // Set all session-contact-keys
                for (let i = 0; i < collection.length; i++)  // Replace all session-ids for the identifed html-elements in the document.
                {
                    collection[i].value =  Modules.modulesVariables.required.contact_key;
                }
                collection = document.getElementsByClassName("ezentrum-session-tickcount") as HTMLCollectionOf<HTMLInputElement> ; // Set all sesion-tickcounts
                for (let i = 0; i < collection.length; i++)  // Replace all session-tickcount for the identifed html-elements in the document.
                {
                    collection[i].value =  Modules.modulesVariables.required.tickcount;
                }
                // Block: 
            }
            if ( typeof Modules.modulesVariables === "string" ) {
                Modules.modulesVariables = this.parseTextToJson( Modules.modulesVariables );
            }
            if ( Modules.modulesVariables.hasOwnProperty("required")) // Identify the session-structure and set the global session status for handling the initialization.
                Modules.isNewSessionStucture = true;
            else
                Modules.isNewSessionStucture = false;
            if ( Modules.isNewSessionStucture ) // Init the sessionid, key + tickcount as identified session-structure
            {
                this.sKontaktID = Modules.modulesVariables.required.sKontaktID;
                this.sKontaktKEY = Modules.modulesVariables.required.sKontaktKEY;
                this.sTICKCOUNT = Modules.modulesVariables.required.tickcount;
            } else {
                this.sKontaktID = Modules.modulesVariables.sKontaktID;
                this.sKontaktKEY = Modules.modulesVariables.sKontaktKEY;
                this.sTICKCOUNT = Modules.modulesVariables.sTICKCOUNT;
            }
        }
        if ( Tools.isDefinedVar( this.sKontaktID, this.sKontaktKEY, this.sTICKCOUNT ) ) {
            this.template = "foundation";
            this.language = new Pair(1, "de");
            this.startTime = new Date().getTime();
            this.endTime = new Date().getTime();
            this.flagAllowCookies = true;
            this.globalConfigurations = new Array();
            this.modules = new Array();
            if ( Modules.isInShopView ) {
                if ( ezentrum_variables.template ) {
                    this.template = ezentrum_variables.template;
                }
            } else {
                if ( Modules.modulesVariables.template ) {
                    this.template = Modules.modulesVariables.template;
                }
            }
            if ( this.configuration ) {
                this.modulesConfiguration = this.configuration["modules"] || null;
                this.globalConfigurations = this.configuration["global"] || null;
                if ( this.modulesConfiguration == null ) {
                    return false;
                } else {
                    var languageID: number;

                    if ( Modules.isInShopView ) {
                        if ( ezentrum_variables.current_language ) {
                            languageID = ezentrum_variables.current_language;
                        }
                    } else {
                        if ( Modules.isNewSessionStucture ) {
                            languageID = Modules.modulesVariables.required.language_number;
                        } else {
                            languageID = Modules.modulesVariables.current_language;
                        }
                    }

                    var languageCode: string = this.getGlobalConfig( "language_mapping." + languageID );
                    if ( languageID && languageCode ) {
                        this.language = new Pair(languageID, languageCode);
                    }
                    return true;
                }
            } else {
                Logging.errorMes("Die Konfigurationsdatei konnte nicht geladen werden oder ist Fehlerhaft.", false);
                Logging.errorMes("Pfad: " + confFilePath, false);
                return false;
            }
        } else {
            Logging.errorMes("Die benötigten Variablen wurde nicht gefunden: sKontaktID, sKontaktKEY und sTICKCOUNT", false);
            return false;
        }
    }

    public preInit(): void {
        Windows.start();
        KeyEvents.start();
        Cookies.start();
    }

    /** @Chris
     * Added async/await functionality so lower part (see the next @Chris tag)
     * @returns void
     */
    public async init():Promise<void> {
        Helpers.init();
        Globals.init();
        Elements.setFocusOnClick();
        if ( this.getGlobalConfig("prevent_special_chars_in_password_fields") == true ) {
            Elements.blockSpecialChars();
        }
        await Promise.all([this.includeModules(), this.includeModulesV2()]);

        this.afterInit();
        /** @Chris
         *  Method to know that the Modules have been completly loaded
         *  Since the above methods are not awaited, might not completely work as intended
         *  Usage:
         *  <input type="radio" checked="false" id="ez_module_loading_complete">
         *  Can be used for custome methods on the page, that are not supposed to be inside this system
        */
        const ModulesLoaded:HTMLInputElement = document.getElementById('ez_module_loading_complete') as HTMLInputElement;
        if ( ! ModulesLoaded) return;
        ModulesLoaded.click();
    }

    public afterInit(): void {
        Collapse.init();
        /** @Chris
        * additional way for an error console
        */
        if ( ( this.configuration.global.debug_mode !== null || this.configuration.global.debug_mode !== undefined) && this.configuration.global.debug_mode ) {
            Logging.logginStart(Modules, Times, this.endTime, this.startTime, this.modules, this.flagAllowCookies, this.modulesConfiguration, this.v2Modals, ModuleExecutionStatus);
            KeyEvents.registerEvent( [ KeyCodes.CTRL, KeyCodes.CMD_LEFT, KeyCodes.L ], Logging.printLogEntrys.bind(this) ); // Error-Log-Consol Output
        }
    }

    private isInDoc(moduleName: string,processedModules:Array<any>): boolean {
        let res:boolean = false;
        if ( processedModules.includes(moduleName) ) return res;

        let tempElement=document.getElementsByClassName("ez-" + moduleName.toLowerCase());
        if ( tempElement && tempElement.length !== 0 ) res = true;
        return res;
    }

    // Method: includeModulesV2
    // Loading Modules V2
    private async includeModulesV2(): Promise<void> {
        console.log('%c includeModulesV2() started: ' + performance.now(), 'background: #000; color: yellow');

        const moduleNames: Array<string> = Object.keys(Object.fromEntries(
            Object.entries(this.modulesConfiguration).filter(([key, value]) => value.active === true && value?.type === "v2")
        ));

        let processedModules: Array<any> = [];

        // Create an array of promises for loading and initializing modules concurrently
        const modulePromises = moduleNames.map(async (moduleName) => {
            const modulesConfiguration: any = (this.modulesConfiguration as any)[moduleName];

            if (modulesConfiguration !== null && modulesConfiguration.type === "v2" && this.isInDoc(moduleName, processedModules)) {
                processedModules.push(moduleName);
                console.log(moduleName + `%c -v2Module import start: ` + performance.now(), 'background: #000; color: red');

                try {
                    // Import the module source -> Lazy loading
                    const widget = await import(`./modules/${moduleName}/${moduleName}`);
                    console.log(moduleName + `%c -v2Module import end: ` + performance.now(), 'background: #000; color: orange');

                    // Handling the module exports for classes
                    const widgetExports: Array<string> = Object.keys(widget);

                    // Loop through all identified export classes and call the constructor to include the classes.
                    for (let j = 0; j < widgetExports.length; j++) {
                        const widgetExport: ObjectConstructor = widget[widgetExports[j]];

                        // Is class derived from the abstract class EZModule?
                        if (widgetExport.prototype instanceof EZModule) {
                            console.log(moduleName + `%c -v2Module init start: ` + performance.now(), 'background: #000; color: pink');

                            const ezModule: EZModule = new widget[widgetExports[j]](modulesConfiguration);
                            ezModule.init(); // Initialize the Module
                            ezModule.run();  // Run Module and assign all events

                            console.log(moduleName + `%c -v2Module init end: ` + performance.now(), 'background: #000; color: lightblue');
                        }
                    }
                } catch (error) {
                    console.error(`Failed to load module ${moduleName}:`, error);
                }
            }
        });

        // Wait for all modules to be processed
        await Promise.all(modulePromises);
        this.v2Modals = processedModules;
        console.log('%c includeModulesV2() ended: ' + performance.now(), 'background: #000; color: yellow');
    }

    // Modules will be loaded, if the html-element has the attribute: data-ez-module-[Modulename]
    // So please ensure, that all modules will have the data-ez-module-[Modulename] html attribute.
    private async includeModules(): Promise<void> {
        console.log('%c includeModules() started: ' + performance.now(), 'background: #000; color: yellow');
        // Section: Loading General Modules
        let ezentrumArticleText: ArticleText = new ArticleText(); // Loading Artikel-Text Replacer
        ezentrumArticleText.run();

        // Loading Dynamic Modules
        let moduleNames: Array<string> = Object.keys(
            Object.fromEntries(
                Object.entries(this.modulesConfiguration).filter(([key, value]) => value.active === true && value?.type !== "v2")
            )
        );

        let modulePromises: Array<Promise<void>> = moduleNames.map(async (moduleName: string) => {
            const moduleElement = document.querySelector("[data-ez-module-" + moduleName + "]"); // Find the module in the HTML site

            if (moduleElement) {
                let modulesConfiguration: any = Json.getSubobject(this.modulesConfiguration, moduleName);

                if (modulesConfiguration != null && (modulesConfiguration.type !== "v2" || modulesConfiguration.type === undefined)) {
                    try {
                        console.log(`${moduleName} --` + '%c v1 import start: ' + performance.now(), 'background: #000; color: orange');
                        let widget = await import("./modules/" + moduleName + "/Module" + moduleName);
                        console.log(`${moduleName} --` + '%c v1 import end: ' + performance.now(), 'background: #000; color: orange');
                        let widgetExports: Array<string> = Object.keys(widget);

                        for (let widgetExportName of widgetExports) {
                            let widgetExport: ObjectConstructor = widget[widgetExportName];

                            if (widgetExport.prototype instanceof Module) {
                                let module: any = new widgetExport(modulesConfiguration);

                                if (module.getName() == "CookieDirective" && module.isActive()) {
                                    this.flagAllowCookies = false;
                                }
                                this.modules.push(module);
                                console.log(moduleName + `%c -v1 init start: ` + performance.now(), 'background: #000; color: pink');
                                this.initModule(module, false);
                                console.log(moduleName + `%c -v1 init end: ` + performance.now(), 'background: #000; color: pink');
                            }
                        }
                    } catch (e) {
                        Logging.errorMes("Das folgende Modul existiert nicht oder konnte nicht aufgerufen werden: " + moduleName, false);
                        Logging.errorMes("Pfad: src/modules/" + moduleName + "/Module" + moduleName + ".ts", false);
                    }
                }
            }
        });

        // Wait for all modules to be loaded
        await Promise.all(modulePromises);

        // Post-load processing
        for (let module of this.modules) {
            if (module.isActive()) {
                for (let controller of module.getControllers()) {
                    if (Interfaces.implements(controller, ["openPopup", "closePopup"])) {
                        try {
                            let overlayController: IOverlay = controller as any;
                            Globals.OVERLAY.getElement().addEventListener("click", overlayController.closePopup.bind(overlayController));
                        } catch (e) {
                            // Handle errors if necessary
                        }
                    }
                }
            }
        }
        this.endTime = new Date().getTime();
        console.log('%c includeModules() ended: ' + performance.now(), 'background: #000; color: yellow');
    }


    public initModules(): void {
        for ( let i = 0; i < this.modules.length; i++ ) {
            this.initModule( this.modules[i], false);
        }
        this.afterInit();
    }

    public initModule( module: Module<Model>, reInit: boolean = false ): void {

        if ( ( !(module.readyToRun()) && !reInit ) || !module.isActive ) return;

        if ( !( !this.flagAllowCookies && module.useCookies() ) ) {
            try {
                if ( reInit ) module.runAllInitializedControllers();
                else module.runWithPreCheck();

                if ( !module.foundError() ) {
                    module.callGlobalModuleEventFunction( "loaded" );
                    module.setExecutionStatus( ModuleExecutionStatus.SUCCESSFULL );
                } else {
                    module.setExecutionStatus( ModuleExecutionStatus.CATCHED_ERROR );
                }
            } catch (error) {
                module.setInitializationCompleted();
                module.setExecutionStatus( ModuleExecutionStatus.NOT_CATCHED_ERROR );
                Logging.errorMes( "Fehler im Modul: " + module.getName(), false );
                Logging.errorMes( error, false );
            }
        } else {
            module.setExecutionStatus( ModuleExecutionStatus.COOKIES );
        }
    }

    // Ruft eine Methode des Modules auf
    public callMethod(modulename: string, methodname: string, ...args: any[]): any {
        var result: any = null;
        for (let i = 0; i < this.modules.length; i++) {
            if (this.modules[i].getName() == modulename) {
                result = this.modules[i].callMethod(methodname, args);

                break;
            }
        }
        return result;
    }

    public parseTextToJson( text:string ): Object {
        let result: Object = null;
        try {
            result = JSON.parse( text );
        } catch(error) {
            console.error(error);
        }
        return result;
    }

    public getGlobalConfig(key:string): any {
        return Json.getSubobject( this.globalConfigurations, key );
    }

    // Globale Funktionen
    public findModule(name: string): Module<Model> // -> Zeigt ein Objekt mit allen Infos des Modules an
    {
        var module: Module<Model> = null;
        for (let i = 0; i < this.modules.length; i++) {
            if (this.modules[i].getName() == name) {
                module = this.modules[i];
                break;
            }
        }
        return module;
    }

    public callGlobalEventFunction( eventName: string ): void {
        Windows.callGlobalFunction( "on" + Strings.beginWithUppercase( eventName ), false );
    }

    public callGlobalEventFunctionWithArgs( eventName: string, ...args: any[] ): void {
        Windows.callGlobalFunction("on" + Strings.beginWithUppercase( eventName ), false, args);
    }

    public getModule( name: string, id: number ): Module<Model> {
        if ( Module.checkControllerAccess( name, id ) ) {
            return this.findModule( name );
        } else {
            return null;
        }
    }

    public checkModuleImplementation( name: string, properties: Array<string> ): boolean {
        let module: Module<Model> = this.findModule( name );
        if ( module != null ) {
            return Interfaces.implements( module, properties );
        } else {
            return false;
        }
    }

    public ModuleInfo( moduleName: string ): void {
        let mod: Module<Model> = this.findModule( moduleName );
        if ( mod != null ) {
            let moduleExecutionStatus: any = mod.getExecutionStatus();
            let showExecutionInformation = moduleExecutionStatus == ModuleExecutionStatus.SUCCESSFULL || moduleExecutionStatus == ModuleExecutionStatus.CATCHED_ERROR;
            if ( showExecutionInformation ) {
                mod.printInfo();
            }
        } else {
            console.log( "Dieses Modul wurde nicht gefunden oder nicht geladen" );
        }
    }

    public moduleInit( moduleName: string, reInit: boolean = false ) {
        let mod: Module<Model> = this.findModule( moduleName );
        if ( mod != null ) {
            this.initModule(mod, reInit);
            console.log( "OK" );
        } else {
            console.log( "Dieses Modul wurde nicht gefunden oder nicht geladen" );
        }
    }
    // Setter
    public allowCookies(): void {
        this.flagAllowCookies = true;
        this.initModules();
    }

    // Getter
    public getLanguageCode(): string {
        return this.language.getValue();
    }

    public getLanguageID(): number {
        return this.language.getKey();
    }

    public getTemplate(): string {
        return this.template;
    }

    public getKontaktKey(): string {
        return this.sKontaktKEY;
    }

    public getKontaktID(): string {
        return this.sKontaktID;
    }

    public getTickcount(): string {
        return this.sTICKCOUNT;
    }
}