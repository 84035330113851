// import { Elements } from "../libs/Elements";
import { Globals } from "./Globals";

export class Collapse {
    public static init(): void {
        /** Collapse open and close events */
        const event: string = "onCollapse";
        const collapses = document.querySelectorAll(`[${Globals.ATTRIBUTE_PREFIX}open]:not([${Globals.EVENT_SET_ATTRIBUTE}='${event}'])`);

        collapses.forEach((collapse) => {
            collapse.addEventListener("click", () => this.collapseToggle(collapse as HTMLElement));
            this.collapseCheckOpen(collapse as HTMLElement);

            (collapse as HTMLElement).setAttribute(Globals.EVENT_SET_ATTRIBUTE, event);
            // Elements.addComment(collapse as HTMLElement, "Collapse: ./src/classes/Collapse.ts");
        });
    }

    /** Toggle the collapse  */
    private static collapseToggle(item: HTMLElement): void {
        const contentId = item.getAttribute(Globals.ATTRIBUTE_PREFIX + "open");
        if (!contentId) return;

        const content = document.getElementById(contentId);
        if (content) {
            if (content.style.display === "none" || content.style.display === "") {
                content.style.display = "block";
                item.setAttribute("is-open", "true");
            } else {
                content.style.display = "none";
                item.setAttribute("is-open", "false");
            }
        }
    }

    /** Set the data-open attributes */
    private static collapseCheckOpen(item: HTMLElement): void {
        const contentId = item.getAttribute(Globals.ATTRIBUTE_PREFIX + "open");
        if (!contentId) return;

        const content = document.getElementById(contentId);
        if (content) {
            if (content.style.display === "none" || content.style.display === "") {
                item.setAttribute("is-open", "false");
            } else {
                item.setAttribute("is-open", "true");
            }
        }
    }
}
